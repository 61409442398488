import React from 'react';

import {
  Input,
} from 'antd';

const {
  Search,
} = Input;

function Status() {
  return(
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}>
      <Search
        placeholder="status id"
        enterButton="Search"
        size="large"
        style={{width:'50%'}}
        onSearch={value => console.log(value)}
      />
    </div>
  );
}

export default Status;
