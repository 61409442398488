import React from 'react';
import { Auth } from 'aws-amplify';
import {
  Link,
} from 'react-router-dom';

import {
  Layout,
  Menu,
} from 'antd';

import {
  UserOutlined,
} from '@ant-design/icons';

const {
  Header,
} = Layout

const {
  SubMenu,
} = Menu;

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

function PlanyBotHeader({
  user,
}) {
  return (
    <Header style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <div>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
        >
          <Menu.Item key="1">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/status">View Status</Link>
          </Menu.Item>
        </Menu>
      </div>
      <div>
        <Menu
          theme="dark"
          mode="horizontal"
        >
          <SubMenu
            icon={<UserOutlined />}
            title={`Welcome ${(user.attributes && user.attributes.name) || 'User'}`}
          >
            <Menu.Item key="account:1" disabled={true}>
              <Link to={'/account'}>
                Account
              </Link>
            </Menu.Item>
            <Menu.Item key="account:2" disabled={true}>
              <Link to={'/team'}>
                Team
              </Link>
            </Menu.Item>
            <Menu.Item
              key="account:3"
              onClick={signOut}
            >Sign Out</Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </Header>
  );
}

export default PlanyBotHeader;
