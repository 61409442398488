/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c5cc00c9-b4e6-49cd-8173-77f17c81ec9f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dgST4pL9q",
    "aws_user_pools_web_client_id": "5vjobvil59e38fr1efkbrman99",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "TestAPI",
            "endpoint": "https://qdssxuji21.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
