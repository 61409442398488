import React from 'react';

import {
  AmplifySignUp,
} from '@aws-amplify/ui-react';

function PlanyBotSignUp() {
  return (
    <AmplifySignUp
      slot="sign-up"
      usernameAlias="email"
      formFields={[
        {
          type: 'name',
          label: 'Name',
          placeholder: 'Firlst and Last name',
        },
        {
          type: 'email',
          label: 'Email Address',
          placeholder: 'john.doe@example.com',
          required: true,
        },
        {
          type: 'password',
          label: 'Password',
          required: true,
        },
        {
          type: 'phone_number',
          label: 'Phone number',
          placeholder: '+1 (234) - 567 - 8910',
          required: true,
        },
      ]}
    />
  );
}

export default PlanyBotSignUp;
