import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import {
  Breadcrumb,
} from 'antd';

import Dashboard from './pages/dashboard';
import Status from './pages/status';
import Account from './pages/account';
import Team from './pages/team';

function PlanyBotRoutes({
  user,
}) {
  return(
    <Switch>
      <Route exact path={'/'}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-content">Content</div>
      </Route>
      <Route path={'/dashboard'}>
        <Dashboard></Dashboard>
      </Route>
      <Route path={'/status'}>
        <Status></Status>
      </Route>
      <Route path={'/account'}>
        <Account></Account>
      </Route>
      <Route path={'/team'}>
        <Team></Team>
      </Route>
    </Switch>
  );
}

export default PlanyBotRoutes;
