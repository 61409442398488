import React from 'react';

import {
  Card,
} from 'antd';

import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons';


function TaskListModule({
  style,
}) {
  return(
    <Card
      style={{
        ...style,
      }}
      actions={[
        <SettingOutlined key="setting" />,
        <EditOutlined key="edit" />,
        <EllipsisOutlined key="ellipsis" />,
      ]}
    >
      <Card.Meta
        title="Group A"
        description="Payroll for Group A"
      />
    </Card>
  );
}

export default TaskListModule;
