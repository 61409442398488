// REACT STUFF
import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

// CSS STUFF
import './App.css';

// AMPLIFY STUFF
import {
  AmplifyAuthenticator,
} from '@aws-amplify/ui-react';
import Amplify, { Auth } from 'aws-amplify';
import awsmobile from './aws-exports';

// ANT-DESIGN STUFF
// TODO: Contribute to ant-design to get rid of that dogamn error message
// TODO: Keep learning Mandarin
// TODO: todo collector (make note to guard against "nested" TODO strings in a line)
import {
  Layout,
} from 'antd';

// STUFF I WROTE
// TODO: remove this if people start working on code actually
import PlanyBotHeader from './components/plany-bot-header';
import PlanyBotFooter from './components/plany-bot-footer';
import PlanyBotSignUp from './components/plany-bot-sign-up';
import PlanyBotSignIn from './components/plany-bot-sign-in';
import PlanyBotRoutes from './routes';
//===============================================================e=
// the import(ant) stuff ^^^^^^^
// the non import stuff  vvvvvvv
// hah a funny joke
//===============================================================e=

const {
  Content,
} = Layout;


Amplify.configure(awsmobile);

function App() {
  const [
    user,
    setUser,
  ] = React.useState({});
  async function _handleAuthStateChange(state) {
    switch (state) {
      case 'signedin':
        const user = await Auth.currentUserInfo();
        setUser(user);
        break;
      default:
        break;
    }
  }
  return (
    <AmplifyAuthenticator
      handleAuthStateChange={_handleAuthStateChange}
    >
      <PlanyBotSignUp></PlanyBotSignUp>
      <PlanyBotSignIn></PlanyBotSignIn>
      <Router>
        <Layout
          className="layout"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <PlanyBotHeader
            user={user}
          ></PlanyBotHeader>
          <Content
            style={{
              padding: '0 50px',
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <PlanyBotRoutes
              user={user}
            ></PlanyBotRoutes>
          </Content>
          <PlanyBotFooter></PlanyBotFooter>
        </Layout>
      </Router>
    </AmplifyAuthenticator>
  );
}

export default App;
