import React from 'react';
import {
  AmplifySignIn,
} from '@aws-amplify/ui-react';

function PlanyBotSignIn() {
  return (
    <AmplifySignIn
      slot="sign-in"
      usernameAlias="email"
    />
  );
}

export default PlanyBotSignIn;
