import React from 'react';

import {
  Layout,
} from 'antd';

const {
  Footer,
} = Layout;

function PlanyBotFooter() {
  return (
    <Footer style={{ textAlign: 'center' }}>Planybot ©2020 Created by Tegusoft</Footer>
  );
}

export default PlanyBotFooter;
