const data = [
    {
          "country": "AD",
          "hot dog": 197,
          "hot dogColor": "hsl(145, 70%, 50%)",
          "burger": 186,
          "burgerColor": "hsl(291, 70%, 50%)",
          "sandwich": 76,
          "sandwichColor": "hsl(129, 70%, 50%)",
          "kebab": 56,
          "kebabColor": "hsl(27, 70%, 50%)",
          "fries": 78,
          "friesColor": "hsl(99, 70%, 50%)",
          "donut": 174,
          "donutColor": "hsl(135, 70%, 50%)"
        },
    {
          "country": "AE",
          "hot dog": 112,
          "hot dogColor": "hsl(179, 70%, 50%)",
          "burger": 42,
          "burgerColor": "hsl(265, 70%, 50%)",
          "sandwich": 175,
          "sandwichColor": "hsl(345, 70%, 50%)",
          "kebab": 191,
          "kebabColor": "hsl(18, 70%, 50%)",
          "fries": 141,
          "friesColor": "hsl(80, 70%, 50%)",
          "donut": 83,
          "donutColor": "hsl(291, 70%, 50%)"
        },
    {
          "country": "AF",
          "hot dog": 146,
          "hot dogColor": "hsl(151, 70%, 50%)",
          "burger": 78,
          "burgerColor": "hsl(46, 70%, 50%)",
          "sandwich": 196,
          "sandwichColor": "hsl(62, 70%, 50%)",
          "kebab": 170,
          "kebabColor": "hsl(173, 70%, 50%)",
          "fries": 194,
          "friesColor": "hsl(47, 70%, 50%)",
          "donut": 64,
          "donutColor": "hsl(90, 70%, 50%)"
        },
    {
          "country": "AG",
          "hot dog": 138,
          "hot dogColor": "hsl(307, 70%, 50%)",
          "burger": 173,
          "burgerColor": "hsl(222, 70%, 50%)",
          "sandwich": 170,
          "sandwichColor": "hsl(130, 70%, 50%)",
          "kebab": 77,
          "kebabColor": "hsl(283, 70%, 50%)",
          "fries": 67,
          "friesColor": "hsl(77, 70%, 50%)",
          "donut": 30,
          "donutColor": "hsl(164, 70%, 50%)"
        },
    {
          "country": "AI",
          "hot dog": 94,
          "hot dogColor": "hsl(68, 70%, 50%)",
          "burger": 81,
          "burgerColor": "hsl(249, 70%, 50%)",
          "sandwich": 71,
          "sandwichColor": "hsl(350, 70%, 50%)",
          "kebab": 188,
          "kebabColor": "hsl(29, 70%, 50%)",
          "fries": 23,
          "friesColor": "hsl(157, 70%, 50%)",
          "donut": 118,
          "donutColor": "hsl(66, 70%, 50%)"
        },
    {
          "country": "AL",
          "hot dog": 151,
          "hot dogColor": "hsl(93, 70%, 50%)",
          "burger": 185,
          "burgerColor": "hsl(129, 70%, 50%)",
          "sandwich": 102,
          "sandwichColor": "hsl(174, 70%, 50%)",
          "kebab": 76,
          "kebabColor": "hsl(103, 70%, 50%)",
          "fries": 28,
          "friesColor": "hsl(278, 70%, 50%)",
          "donut": 168,
          "donutColor": "hsl(105, 70%, 50%)"
        },
    {
          "country": "AM",
          "hot dog": 43,
          "hot dogColor": "hsl(58, 70%, 50%)",
          "burger": 131,
          "burgerColor": "hsl(334, 70%, 50%)",
          "sandwich": 42,
          "sandwichColor": "hsl(178, 70%, 50%)",
          "kebab": 61,
          "kebabColor": "hsl(272, 70%, 50%)",
          "fries": 152,
          "friesColor": "hsl(276, 70%, 50%)",
          "donut": 193,
          "donutColor": "hsl(127, 70%, 50%)"
        }
];

export default data;
