import React from 'react';

import {
  Card,
  Progress,
  List,
  Typography,
  Button,
  Tooltip,
  Space,
} from 'antd';

import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const data = [
  'ray',
  'john',
];

function TaskListModule({
  style,
}) {
  return(
    <List
      header={<div>Planilla Statuses</div>}
      style={{
        backgroundColor: '#FFF',
        padding: '0 40px 16px 24px',
        borderRadius: '5px',
        ...style,
      }}
      dataSource={data}
      renderItem={item => (
        <List.Item>
          {item}
          <br></br>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Progress
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              percent={99.9}
            />
            <div
              style={{
                width: '64px',
              }}
            ></div>
            <Tooltip title="search">
              <Button
                type="primary"
                shape="circle"
                icon={<EllipsisOutlined />}
              />
            </Tooltip>
          </div>
        </List.Item>
      )}
    />
  );
}

export default TaskListModule;
