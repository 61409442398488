import React from 'react';

import {
  Tabs,
  Carousel,
  Card,
  Statistic,
  Row,
  Col,
} from 'antd';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LikeOutlined,
} from '@ant-design/icons';

import TaskListModule from './modules/tasklist/index.js';
import StatusListModule from './modules/statuslist/index.js';
import StackedHistogramModule from './modules/stacked-histogram/index.js';

const {
  TabPane,
} = Tabs;

const modules = [
  TaskListModule,
  StatusListModule,
  StackedHistogramModule,
];

function Dashboard() {
  const wrappedModules = modules.map((m, i) => <Col
    span={8}
    key={i}
  >{m({
    style: {
      boxShadow: '0px 0px 10px 0px #CCC',
    },
  })}</Col>);
  return(
    <div
      className="card-container"
      style={{
        flex: '1 1 auto',
        marginTop: '10px',
      }}
    >
      <Tabs
        defaultActiveKey={"1"}
        type="editable-card"
        style={{
          width:'100%',
          height: '100%',
        }}
      >
        <TabPane
          tab="Dashboard A"
          key="1"
          style={{
            height: '100%',
            backgroundColor: '#EEE',
            boxShadow: '0px 0px 10px 0px #CCC inset',
          }}
        >
          <Row
            justify="start"
            align="top"
            gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          >
            {wrappedModules}
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Dashboard;
